.headerBg {
   position: -webkit-sticky; /* Safari */
   position: sticky;
   top: 0;
   background-color: #FFF5E0;
   border-bottom: 1px solid gray;
   z-index: 10000;
 }
 /* .site-header  */
 .main-navigation{
  line-height: 15px;
  letter-spacing: 0px;
  font-weight: 500;
  font-style: normal;
  padding: 15px 15px;
  padding-left: 0;
  margin: 0;
  text-transform: uppercase;
}
.nav-link{
  font-size: 0.9rem;
  margin-left: 1rem;
  color: #000000;
  font-style: normal;
  font-weight: 600;
}
.nav-link:hover{
  color: #c4b320;
}
 .btnstyle{
  background-color: #01bdb2;
  color: #ffffff;
  text-transform: uppercase;
 }
 .btn:hover{
  background-color: #142959;
}
.cta {
    position: relative;
    margin: auto;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .cta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: #f2ff35;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
  }
  
  .cta span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #234567;
  }
  
  .cta svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    strokeLinecap: round;
    strokeLinejoin: round;
    stroke: #234567;
    strokeWidth: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
  }
  
  .cta:hover:before {
    width: 100%;
    background: #eeff00;
  }
  
  .cta:hover svg {
    transform: translateX(0);
  }
  
  .cta:active {
    transform: scale(0.95);
  }
  .resized-image {
    width: 100px; /* Adjust this value to resize the image as needed */
    height: auto; 
    /* padding-left: 20%; */
  }
  .resized-icon {
    width: 40px; /* Adjust this value to resize the image as needed */
    height: auto; 
    /* padding-left: 20%; */
  }
  .btn2 {
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    border: 2px solid #140101;
    width: max-content;
    /* text-transform: uppercase; */
    color: #1b0000;
    text-decoration: none;
    font-weight: 300;
    /* font-size: 20px; */
    letter-spacing: 1px;
    transition: 0.3s;
  }
  
  .btn2::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% - -2px);
    background-color: #FFF5E0;
    transition: 0.3s ease-out;
    transform: scaleY(1);
  }
  
  .btn2::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% - 50px);
    background-color: #ffffff;
    transition: 0.3s ease-out;
    transform: scaleY(1);
  }
  
  .btn2:hover::before {
    transform: translateY(-25px);
    height: 0;
  }
  
  .btn2:hover::after {
    transform: scaleX(0);
    transition-delay: 0.15s;
  }
  
  .btn2:hover {
    border: 2px solid #000000;
  }
  
  .btn2 span {
    position: relative;
    z-index: 3;
  }
  
  button {
    text-decoration: none;
    border: none;
    background-color: transparent;
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 1640px;
    }
  }
  
/* ServiceCard.css */

.heading-title-sections {
  font-size: 3rem; /* Adjust the font size as needed */
  color: #58b105; /* Change the color to your preference */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Add a subtle text shadow for depth */
background-color: #ffffff;
}
.heading-title-h {
  font-size: 2rem; /* Adjust the font size as needed */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Add a subtle text shadow for depth */
background-color: #fefffe;
}
.paragraph-text {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Add a subtle text shadow for depth */
background-color: #fefffe;
}
.p-text {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Add a subtle text shadow for depth */
/* background-color: #fefffe; */
}
.heading-title-s {
  font-size: 2rem; /* Adjust the font size as needed */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Add a subtle text shadow for depth */
}
.metric-name {
  font-size: 1rem; /* Adjust the font size as needed */
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.3); /* Add a subtle text shadow for depth */
}

.subheading {
  font-size: 1.5rem; /* Adjust the font size as needed */
  color: #2b2b2b; /* Change the color to your preference */
  margin-top: 0.5rem; /* Adjust the margin as needed */
}



/* styles.css */
.link {
  color: inherit; /* This will inherit the color from its parent */
  text-decoration: none; /* Remove underline */
}
/* Footer.css */
.facebook-icon {
  color: #3b5998; /* Facebook blue */
}

.instagram-icon {
  color: #e4405f; /* Instagram pink */
}

.twitter-icon {
  color: #1da1f2; /* Twitter blue */
}
.linkedIn-icon {
  color: #1da1f2;
}
.youtube-icon {
  color: #f21d1d;
}
.whatsapp-icon {
  color: #00ff37; 
}

.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #4c4c4c;
  /* line-height: 5rem; */
  max-width: 700px;
}
.swipe {
  position: relative;
  background-color: #F1F7F7;
  width: 280px;
  height: 60px;
  color: rgb(49, 49, 49);
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  letter-spacing: 2px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.35);
  border-left: 0.5px solid rgba(0, 0, 0, 0.35);
  padding-left: 40px;
  cursor: pointer;
  transition: 0.35s ease;
}

.swipe:hover {
  padding-left: 0;
  padding-right: 40px;
  color: #c4b320;
}

.containerbtn {
  position: absolute;
  left: 5px;
  width: 50px;
  height: 50px;
  background: #7AB5B7;
  border-radius: 50%;
  transition: 0.35s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f1f4f7;
}

.swipe:hover .containerbtn {
  left: calc(100% - 55px);
  color: #324B4C;
}
.pointer-box {
  position: relative;
  background-color: #f9f9f9; /* Background color */
  border: 2px solid #e0e0e0; /* Border color */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding inside the box */
  width: 300px; /* Width of the box */
  max-width: 150%; /* Maximum width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
}

.pointer-box::before {
  content: '';
  position: absolute;
  top: 50%; /* Position at the middle vertically */
  right: 100%; /* Position to the left of the box */
  margin-top: -10px; /* Half of the arrow height */
  border-width: 10px; /* Size of the arrow */
  border-style: solid;
  border-color: transparent #e0e0e0 transparent transparent; /* Arrow color */
}
.radius6 {
  border-radius: 0.6rem;
}
.darkBg {
  background-color: #0B093B;
}
.whycounselling {
  background-color: #f0fdf5;
  padding: 60px 20px;
  text-align: center;
}

.whycounselling h2 {
  color: #333;
  font-size: 36px;
  margin-bottom: 40px;
}

.whycounselling h2 span {
  background-color: #7ab5b7;
  color: #ffffff;
  border-radius: 50%;
  padding: 15px 20px;
}
.importance-section h2 span {
  background-color: #e7eb13;
  color: darkcyan;
  border-radius: 50%;
  padding: 20px 25px;
  display: -webkit-inline-box;
}

.importance-section {
  background:#dbfddb;
  border-radius: 50px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
  transition: transform 0.2s ease-in-out;
}
.importance-section::before{
  height: 100px;
  width: 100px;
  position: absolute;
  top: -40%;
  left: -20%;
  border-radius: 50%;
  border: 35px solid rgba(255, 255, 255, 0.102);
  transition: all .8s ease;
  filter: blur(.5rem);

}
.importance-section:hover {
  transform: translateY(-5px);
}

.importance-section h3 {
  color: #332d2d;
  font-size: 28px;
  margin-bottom: 20px;
}

.importance-section li {
  color: #000000;
  font-size: 22px;
  line-height: 1.6;
}


@media (max-width: 768px) {
  .whycounselling h2 {
    font-size: 30px;
  }
}
.trustSectionBg {
  background-color: #4d4d51;
}
.SectionBg {
  background-color: #404240;
}
.contentecenter {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.eachmetric {
  text-align: center;
}
.metric-icon {
  font-size: 24px;
}


.OurServiceMainHeadingBackground{
  background-color: #01bdb2;
  color: #ffffff;
  }

  .OurServiceMainHeadingBackgroundImage{
    background: url(/src/assets/images/pettern-01.png);
    
}

  .BackGroundColorChange {
    position: relative;
    border-top: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    padding: 2rem 1rem 2rem 1rem;
    text-align: justify;
    background-color: #01bdb2;
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}
.BackGroundImage {
  background-image: url(/src/assets/images/student\ .png);
}

.BackGroundImageMiddle {
  background-image: url(/src/assets/images/teacherwithstudent.png);
}
.BackGroundColorChange::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    background-color: #142959;
    opacity: 0.8;
}

.BackGroundColorChange::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    background-color: #01bdb2;
    -webkit-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out;
}

.BackGroundColorChange:hover::after {
    opacity: 0;
    height: 0;
}

.HomeServiceContaient {
    text-transform: capitalize;
    position: relative;
    z-index: 999;
    color: #ffffff;
}

.HomeServiceIcon {
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
    font-size: 3rem;
    background: rgba(225, 225, 225, 0.3);
    text-align: center;
    border-radius: 100%;
}

.HomeServiceContent {
    margin-bottom: 3rem;
}

.cta {
  text-decoration: none; /* Remove underline */
}
.myeduallies{
  padding: 80px 0;
}
.PatnerImageContainer img {
  border-radius: 100%;
  width: 100%;
  display: initial;
  position: relative;
  z-index: 9;
 }
 .PatnerImageMain{
  position: relative;
  padding: 25px;
 }
 .PatnerImageMain::before{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50%;
  content: "";
  border-radius: 200px 200px 0 0;
  transition: all .7s ease;
  background:#f6f6f6;
 } 
 .PatnerImageMain::after{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50%;
  content: "";
  border-radius: 200px 200px 0 0;
  transition: all .4s ease;
  -webkit-clip-path: polygon(50% 0,50% 0,50% 50%,50% 100%,50% 100%,50% 50%);
  clip-path: polygon(50% 0,50% 0,50% 50%,50% 100%,50% 100%,50% 50%);
  background: linear-gradient(to left, #01bdb2, #01bdb2, #01bdb2,#01bdb2);

 }
 .PatnerImageContainer:hover .PatnerImageMain::after{
  -webkit-clip-path: polygon(25% -70%,75% -70%,120% 50%,75% 170%,25% 170%,-20% 50%);
  clip-path: polygon(25% -70%,75% -70%,120% 50%,75% 170%,25% 170%,-20% 50%);
 }
 .PatnerNameData{
  position: relative;
 }
.PatnerNameText {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color:#142959;
  margin: 10px 0px 5px 0px;
}
.Patnerdesignation{
  font-size: 15px;
  line-height: 20px;  
  font-weight: 600;
  text-transform: uppercase;
  color:#6d7a8c;
}

.counselor-section {
  margin: 50px auto;
  width: 80%;
}

.counselor-slider {
  display: flex;
  overflow-x: auto;
}

.counselor-box {
  flex: 0 0 auto;
  width: 200px;
  margin-right: 20px;
}

.counselor-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 10px;
}

.counselor-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.counselor-details {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .counselor-box {
    width: 150px;
  }
}

