.container-plans{
      background-color: #f4fffe;

}
.card {
    max-width: 350px;
    margin: auto;
    display: flex;
    flex-direction: column;
    border-radius: 1.5rem;
    background-color: #f0f0f0; /* Light gray background */
    padding: 1.5rem;
    /* box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3); */
    margin: auto;
    transition: all 0.3s ease; /* Smooth transition on hover */
  }
  .card2 {
    display: flex;
    flex-direction: column;
    border-radius: 1.5rem;
    background-color: #f0f0f0; /* Light gray background */
    padding: 1.5rem;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
    margin: auto;
    transition: all 0.3s ease; /* Smooth transition on hover */
    max-width: 800px; /* Adjust the max-width to make the card wider */
    margin: auto;
}

  
  .card:hover {
    transform: translateY(-5px); /* Move card up slightly on hover */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* Add shadow effect on hover */
  }
  .card2:hover {
    transform: translateY(-5px); /* Move card up slightly on hover */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* Add shadow effect on hover */
  }
  
  .price {
    font-size: 3rem;
    line-height: 1;
    font-weight: 600;
    color: #5a5a5a; /* Dark text color */
  }
  
  .lists {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    font-size: 0.900rem;
    line-height: 1.25rem;
    color: #313131;
    list-style-type: none; /* Remove bullets */
    
  }
  .lists2 {
    margin-top: 2.5rem;
    display: flex;
    /* flex-direction: column; */
    gap: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #313131;
    list-style-type: none; /* Remove bullets */
  }
  
  
  .list {
    align-items: center;
  }
  
  .list svg {
    height: 1rem;
    width: 1rem;
    fill: #444; /* Dark gray icon color */
  }
  
  .list span {
    margin-left: 1rem;
  }
  
  .action {
    margin-top: 2rem;
    width: 100%;
    border: 2px solid #9ce14e; /* Pink border color */
    border-radius: 9999px;
    background-color: #4ee17a; /* Pink background color */
    padding: 0.625rem 1.5rem;
    font-weight: 600;
    text-align: center;
    font-size: 0.875rem;
    color: #fff; /* White text color */
    outline: none;
    text-decoration: none;
    transition: all 0.3s ease; /* Smooth transition on hover */
  }
  
  .action:hover {
    color: #4ee167; /* Pink text color on hover */
    background-color: transparent; /* Transparent background color on hover */
  }

  .center {
    display: flex;
    justify-content: center;
    margin-top: 10px; /* Adjust as needed */
}

.plus {
    font-size: 50px;
    font-weight: bold;
    color: #1658e6;
}


.lists-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start */
}

.world-flag-bg {
  background: linear-gradient(110deg, #2ca5d4 , #ffffff, #40ca34 ); 

}
/* ServiceCard.css */

/* Define background with Indian flag colors and Ashoka Chakra */
.indian-flag-bg {
  background: linear-gradient(110deg, #ffc266, #ffffff, #26bb18), url('../../public/Ashoka-Chakra.png');
  background-position: center center; /* Center the background image horizontally and vertically */
  background-size: auto, contain; /* Adjust sizing to auto for the gradient and contain for the image */
  background-repeat: no-repeat;
  color: #333;
}

/* Apply opacity only to the Ashoka Chakra image */
.indian-flag-bg::after {
  content: ""; /* Add a pseudo-element to apply the opacity */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../public/Ashoka-Chakra.png');
  opacity: 0.1;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
