.counsellor-card {
  width: auto;
  height: 450px; /* Increase the height as needed */
  background: white;
  border-radius: 32px;
  padding: 3px;
  position: relative;
  box-shadow: #604b4a30 0px 70px 30px -50px;
  transition: all 0.5s ease-in-out;
}


.counsellor-card .mail {
  position: absolute;
  right: 2rem;
  top: 1.4rem;
  background: transparent;
  border: none;
}

.counsellor-card .mail svg {
  stroke: #56f556;
  /* strokeWidth: 3px; */
}

.counsellor-card .mail svg:hover {
  stroke: #56f556;
}

.counsellor-card .profile-pic {
  position: absolute;
  width: calc(100% - 6px);
  height: calc(125% - 30px);
  top: 3px;
  left: 3px;
  border-radius: 29px;
  z-index: 1;
  border: 0px solid #56f556;
  overflow: hidden;
  transition: all 0.5s ease-in-out 0.2s, z-index 0.5s ease-in-out 0.2s;
}

.counsellor-card .profile-pic img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 110%;
  -o-object-position: center;
  object-position: center;
  transition: all 0.5s ease-in-out 0s;
}


.counsellor-card .profile-pic svg {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 0px 0px;
  object-position: 0px 0px;
  transform-origin: 45% 20%;
  transition: all 0.5s ease-in-out 0s;
}

.counsellor-card .bottom {
  position: absolute;
  bottom: 3px;
  left: 3px;
  right: 3px;
  background: #69e969;
  top: 85%;
  border-radius: 29px;
  z-index: 2;
  box-shadow: rgba(96, 75, 74, 0.1882352941) 0px 5px 5px 0px inset;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.counsellor-card .bottom .content {
  position: absolute;
  bottom: 120px;
  left: 1.5rem;
  right: 1.5rem;
  height: 208px;
}

.counsellor-card .bottom .content .name {
  display: block;
  font-size: 1.2rem;
  color: white;
  font-weight: bold;
}

.counsellor-card .bottom .content .about-me {
  display: block;
  font-size: 0.9rem;
  color: white;
  /* margin-top: 0rem; */
}

.counsellor-card .bottom .bottom-bottom {
  position: absolute;
  bottom: 1rem;
  left: 1.5rem;
  right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.counsellor-card .bottom .bottom-bottom .social-links-container {
  /* display: flex; */
  gap: 2rem;
  color: white;

}

.counsellor-card .bottom .bottom-bottom .social-links-container svg {
  height: 20px;
  fill: white;
  filter: drop-shadow(0 5px 5px rgba(165, 132, 130, 0.1333333333));
}

.counsellor-card .bottom .bottom-bottom .social-links-container svg:hover {
  fill: #56f556;
  transform: scale(1.2);
}

.counsellor-card .bottom .bottom-bottom .button {
  background: white;
  color: #56f556;
  border: none;
  border-radius: 20px;
  font-size: 0.6rem;
  padding: 0.4rem 0.6rem;
  box-shadow: rgba(165, 132, 130, 0.1333333333) 0px 5px 5px 0px;
}

.counsellor-card .bottom .bottom-bottom .button:hover {
  background: #56f556;
  color: white;
}

.counsellor-card:hover {
  border-top-left-radius: 55px;
}

.counsellor-card:hover .bottom {
  top: 20%;
  border-radius: 80px 29px 29px 29px;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
}

.counsellor-card:hover .profile-pic {
  width: 100px;
  height: 100px;
  aspect-ratio: 1;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  z-index: 3;
  border: 7px solid #56f556;
  box-shadow: rgba(96, 75, 74, 0.1882352941) 0px 5px 5px 0px;
  transition: all 0.5s ease-in-out, z-index 0.5s ease-in-out 0.1s;
}

.counsellor-card:hover .profile-pic:hover {
  transform: scale(1.3);
  border-radius: 0px;
}

.counsellor-card:hover .profile-pic img {
  width: calc(100% + 14px); /* Adjust this value according to your design */
  height: calc(100% + 14px); /* Adjust this value according to your design */
  -o-object-position: center;
  object-position: center;
  transition: all 0.5s ease-in-out 0s;
}

.counsellor-card:hover .profile-pic svg {
  transform: scale(2.5);
  transition: all 0.5s ease-in-out 0.5s;
}
